<script setup>
import AntInput from '@/components/AntInput.vue';
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';

const projectHeaders = [
  { text: '#', value: 'project_number', align: 'left', hasSlot: true },
  { text: 'Naam', value: 'name', hasSlot: true },
  { text: 'Aanmaak datum', value: 'created', align: 'right' },
];
</script>

<script>
import { mapActions, mapGetters } from 'vuex';
import DynamicDataTable from '@/components/DynamicDataTable.vue';

const store = 'dhmeProjectIntakeStore';

export default {
  name: 'DhmeProjectIntakeHome',
  components: { DynamicDataTable },
  data: () => ({
    deleteConceptDialog: null,
    createProjectDialog: null,
    creatingProject: false,
    rules: {
      required: (value) => !!value || 'Required.',
      maxLength: (value) =>
        (value && value.length < 250) || 'Max 250 characters',
    },
    searchInput: '',
  }),
  computed: {
    ...mapGetters({
      projects: 'dhmeProjectIntakeStore/projects',
      isLoading: 'dhmeProjectIntakeStore/isLoading',
      isDaiwaLicense: 'isDaiwaLicense',
    }),

    filteredProjects() {
      if (this.searchInput) {
        return this.projects.filter(
          (p) =>
            p.name.toLowerCase().includes(this.searchInput.toLowerCase()) ||
            p.project_number
              .toLowerCase()
              .includes(this.searchInput.toLowerCase())
        );
      }
      return this.projects;
    },
  },
  async mounted() {
    this.$store.commit('dhmeProjectIntakeStore/reset');
    await this.loadTablesIfNotExists();
    await this.loadProjectsMetaData();
  },
  methods: {
    ...mapActions(store, [
      'loadTablesIfNotExists',
      'loadProjectsMetaData',
      'selectProject',
      'createProject',
    ]),
    showProjectDialog() {
      this.createProjectDialog = { name: '' };
    },
    async closeProjectDialog(save) {
      if (!this.creatingProject) {
        const name = this.createProjectDialog?.name;

        if (save && name && this.$refs.form.validate()) {
          this.creatingProject = true;
          const project = await this.createProject(name);
          this.creatingProject = false;
          this.showProject(project);
        }
        this.$refs.form.reset();
        this.createProjectDialog = null;
      }
    },
    showProject(project) {
      return this.$router.push({
        name: 'dhme-project-intake-info',
        params: { projectId: project.project_id },
      });
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Project Intake">
      <template slot="module-nav-logo">
        <img
          v-if="isDaiwaLicense"
          alt=""
          height="40px"
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
        />
      </template>
    </module-navigation-bar>
    <v-progress-linear
      v-show="isLoading"
      color="primary"
      height="5px"
      indeterminate
    />
    <div class="flex-1 full-width pa-5 overflow-y-auto">
      <dynamic-data-table
        :items-per-page="10"
        :table-headers="projectHeaders"
        :table-records="filteredProjects"
        auto-sort-column="created"
        class="background-white"
        clickable-rows
        sort-desc
        table-title="Projecten"
        @clickedRow="showProject"
      >
        <template #item.project_number="{ item, rowId, header, value }">
          <span v-if="!(value?.startsWith('C#') && value?.length === 7)">{{
            value
          }}</span>
          <span v-else>-</span>
        </template>
        <template #item.name="{ item, rowId, header, value }">
          <v-chip
            :color="item['is_concept'] ? 'warning' : 'primary'"
            class="justify-center mr-5"
            small
            style="width: 80px"
            >{{ item['is_concept'] ? 'concept' : 'project' }}
          </v-chip>
          {{ value }}
        </template>
        <template #table-actions>
          <v-text-field
            v-model="searchInput"
            class="mr-5"
            dense
            filled
            hide-details
            placeholder="Zoeken"
            prepend-inner-icon="mdi-magnify"
            style="max-width: 200px"
          />
          <v-dialog
            key="add-edit-collection"
            :persistent="creatingProject"
            :value="createProjectDialog"
            max-width="500px"
            @click:outside="closeProjectDialog"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                :disabled="creatingProject"
                :loading="creatingProject"
                color="primary"
                elevation="0"
                small
                @click="showProjectDialog"
              >
                <v-icon>mdi-plus</v-icon>
                Voeg concept toe
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="justify-center text-uppercase headline">
                Voeg concept toe
              </v-card-title>
              <v-divider />
              <v-form ref="form" @submit.prevent>
                <div class="px-10 pb-5">
                  <ant-input :is-optional="false" label="Project naam">
                    <template #input-field>
                      <v-text-field
                        :rules="[rules.required, rules.maxLength]"
                        :value="createProjectDialog?.name"
                        counter="250"
                        dense
                        filled
                        hide-details
                        maxlength="250"
                        placeholder="Naam"
                        single-line
                        @change="
                          (e) =>
                            createProjectDialog?.name !== undefined &&
                            (createProjectDialog.name = e)
                        "
                        @keyup.enter="closeProjectDialog(true)"
                      />
                    </template>
                  </ant-input>
                </div>
                <v-card-actions class="ant-border-top ant-dialog-actions-bg">
                  <v-spacer />
                  <v-btn
                    :disabled="creatingProject"
                    color="error"
                    small
                    text
                    @click.stop="closeProjectDialog(false)"
                  >
                    Annuleren
                  </v-btn>
                  <v-btn
                    :disabled="creatingProject"
                    :loading="creatingProject"
                    color="primary"
                    elevation="0"
                    small
                    @click.stop="closeProjectDialog(true)"
                  >
                    Toevoegen
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card>
          </v-dialog>
        </template>
      </dynamic-data-table>
    </div>
  </div>
</template>
